/* eslint-disable */

<template>

  <div class="profile-panel">
    <div class="profile-container">
      <image-input v-model="avatar">
        <div slot="activator">
          <img id="profile-img" class="profile-img-card" :src="computedProfileImage" v-bind:style="{'border-radius': avatar ? '100px' : ''}" v-tooltip="'Avatar ändern'" style="cursor: pointer"/>
        </div>

      </image-input>
      <div v-if="avatar && savedImage == false" class="footer no-divider" style="position: absolute; margin-top: -50px;">
        <div class="align-left">
          <buttonc type="footer green " label="Profilbild speichern"  @click="changeProfileImage()"/>
        </div>
      </div>
      <p id="profile-name" class="profile-name-card">{{firstname}} {{lastname}}</p>
      <div class="profile-user-info">
        <div class="entry divider">
          <div class="line-through"></div>
        </div>
        <div class="entry">
          <labelc text="E-Mail" v-bind:style="{width: labelWidth + 'px'}"/>
          <labelc type="data" :text="email"/>
          <buttonc type="edit no-hover icon-green-hover" icon="fa-pencil-square" @click="OpenChangeEmailPopup()"/>
        </div>
        <div class="entry">
          <labelc text="Vorname" v-bind:style="{width: labelWidth + 'px'}"/>
          <labelc type="data" :text="firstname"/>
          <buttonc type="edit no-hover icon-green-hover" icon="fa-pencil-square" @click="OpenChangeFirstNamePopup()"/>
        </div>
        <div class="entry">
          <labelc text="Nachname" v-bind:style="{width: labelWidth + 'px'}"/>
          <labelc type="data" :text="lastname"/>
          <buttonc type="edit no-hover icon-green-hover" icon="fa-pencil-square" @click="OpenChangeLastNamePopup()"/>
        </div>
        <div class="entry" v-if="twofactor && !isBlank(telephone)">
          <labelc text="Telefon" v-bind:style="{width: labelWidth + 'px'}"/>
          <labelc type="data" :text="telephone"/>
          <buttonc type="edit no-hover icon-green-hover" icon="fa-pencil-square" />
        </div>
        <div class="entry">
          <labelc text="Passwort" v-bind:style="{width: labelWidth + 'px'}"/>
          <labelc type="data" text="**********"/>
          <buttonc type="edit no-hover icon-green-hover" icon="fa-pencil-square" @click="OpenChangePasswordPopup()"/>
        </div>
        <div class="entry">
          <labelc text="2F Auth" v-bind:style="{width: labelWidth + 'px'}"/>
          <labelc type="data" :text="twofactor ? 'Aktiviert' : 'Deaktiviert'"/>
          <buttonc type="edit no-hover icon-green-hover" :icon="twofactor ? 'fa-check green' : 'fa-circle'" @click="Toggle2Factor()" style="margin-right: -2px;"/>
        </div>
        <div class="entry" v-if="isAdmin">
          <buttonc type="edit no-hover icon-green-hover" label="Weiter zur Benutzerübersicht"  @click="RouterToOverview()" style="width: 90px;"/>
        </div>
      </div>
      <div class="link-container">
        <img src="../../assets/serviceimg/mp.png" class="mp" @click="window.open('https://www.meinplayer.at', '_blank')"/>
        <img src="../../assets/serviceimg/mz.png" @click="window.open('https://www.meinezeiterfassung.at', '_blank')"/>
        <img src="../../assets/serviceimg/md.png" @click="window.open('https://web.meindienstplan.at', '_blank')"/>
      </div>
      <div class="footer">
        <div class="align-left" style="justify-content: center !important;">
          <buttonc type="footer dark" label="Abmelden"  @click="logout()"/>
        </div>
        <!--<div class="align-right"></div>-->
      </div>
    </div><!-- /card-container -->
  </div><!-- /container -->
</template>

<script>
    import axios from 'axios';
    import VueQr from 'vue-qr';
    import ImageInput from '@/components/ImageInput.vue'
    export default {
        name: 'profilepanel',
        data () {
            return {
                id: 0,
                email:"",
                newemail:"",
                profileimage:"",
                firstname: "",
                newfirstname:"",
                lastname: "",
                newlastname:"",
                telephone: "",
                token:"",
                password:"",
                newpassword:"",
                twofactor: false,
                isAdmin: false,
                avatar: null,
                twofactorConfirm: null,
                totpuri: "",
                savedImage: false,
                twofactortype: "NONE",
                temptwofactortype: "GAUTH",
                labelWidth: 120,
                window: window,
            }
        },
        created: function () {
            this.id = this.$route.params.id;
            this.token = localStorage.getItem('md_token')
            if(this.isBlank(this.token)){
                this.returnToIndex();
            } else {
                let claims = this.extractJWTInfo(this.token);
                if(claims.role === 'ADMIN'){
                    this.isAdmin = true;
                }
                this.loadProfile();
            }
        },
        components: {
            ImageInput: ImageInput,
            VueQr
        },
        // methods that implement data logic.
        methods : {
            isBlank: function (str) {
                return (!str || /^\s*$/.test(str));
            },
            getQueryStringValue: function (key) {
                return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
            },
            extractJWTInfo: function(token){
                if (token) return JSON.parse(atob(token.split('.')[1]));
                return {};

            },
            loadProfile : function() {
                let view = this;
                axios({
                    method: 'get',
                    url: '/api/user?id='+this.id,
                    headers: {'Authorization': 'Bearer ' + this.token}
                }).then(response => {
                    this.firstname = response.data.firstname;
                    this.lastname = response.data.lastname;
                    this.profileimage = response.data.profileimage;
                    this.email = response.data.email;
                    this.telephone = response.data.telephone;
                    this.twofactor = response.data.twofactor;
                    this.twofactortype = response.data.twofactortype;
                }).catch(function (error) {
                    if(error.response != undefined && error.response.data.message != undefined){
                        view.toggleModal('Fehler', '<p>Benutzerdaten konnten nicht geladen werden.</p><p>Bitte versuchen Sie es erneut oder kontaktieren Sie einen Administrator.</p>');
                    }
                })

            },
            changeProfileImage: function(){
                let view = this;
                var payload = {
                    command: "updateProfileImage",
                    id: this.id,
                    profileimage: this.avatar.imageURL
                }
                let formData = this.avatar.formData;
                formData.set("id", this.id)
                axios.post('/api/profileimage', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': 'Bearer ' + this.token
                    }
                }).then(response => {
                    view.profileimage = view.avatar.imageURL;
                    view.savedImage = true;
                }).catch(function (error) {
                    if(error.response != undefined && error.response.data.message != undefined){
                        view.toggleModal('Fehler', '<p>Änderung konnte nicht durchgeführt werden.</p><p>Bitte versuchen Sie es erneut oder kontaktieren Sie einen Administrator.</p>');
                    }
                })
            },
            changeFirstName: function(firstname){
                let view = this;
                var payload = {
                    command: "updateFirstName",
                    id: this.id,
                    firstname: firstname
                }
                return axios({
                    method: 'put',
                    url: '/api/user',
                    headers: {'Authorization': 'Bearer ' + this.token},
                    data: payload
                })
            },
            changeLastName: function(lastname){
                let view = this;
                var payload = {
                    command: "updateLastName",
                    id: this.id,
                    lastname: lastname
                }
                return axios({
                    method: 'put',
                    url: '/api/user',
                    headers: {'Authorization': 'Bearer ' + this.token},
                    data: payload
                })
            },
            changeEmail: function(newEmail, password){
                let view = this;
                var payload = {
                    command: "updateEmail",
                    id: this.id,
                    email: newEmail,
                    password: password
                }
                return axios({
                    method: 'put',
                    url: '/api/user',
                    headers: {'Authorization': 'Bearer ' + this.token},
                    data: payload
                })

                    /*.then(response => {
                    view.email = view.newemail
                    view.$modal.hide('changeEmailModal');
                    view.newemail = "";
                    view.password = "";
                }).catch(function (error) {
                    view.newemail = "";
                    view.password = "";
                    view.$modal.hide('changeEmailModal');
                    if(error.response != undefined && error.response.data.message != undefined){
                        view.toggleModal('Fehler', '<p>Änderung konnte nicht durchgeführt werden.</p><p>Bitte versuchen Sie es erneut oder kontaktieren Sie einen Administrator.</p>');
                    }
                })*/
            },
            changePassword: function(password, newpassword){
                let view = this;
                var payload = {
                    command: "updatePassword",
                    id: this.id,
                    newpassword: newpassword,
                    password: password
                }
                return axios({
                    method: 'put',
                    url: '/api/user',
                    headers: {'Authorization': 'Bearer ' + this.token},
                    data: payload
                })
            },
            requestTwoFactor: function (type ) {
                this.hideCustomModal('chooseTwoFactor');
                var payload = {
                    command: "requestTwoFactor",
                    id: this.id
                }
                if (type === 'EMAIL'){
                    payload.command = "requestEmailTwoFactor";
                }

                let token = localStorage.getItem('md_token')
                let view = this;
                return axios({
                    method: 'put',
                    url: '/api/user',
                    headers: {'Authorization': 'Bearer ' + token},
                    data: payload
                })
            },
            activateTwoFactor: function (twofactorcode) {

                var payload = {
                    command: "activateTwoFactor",
                    id: this.id,
                    password: twofactorcode
                }
                let token = localStorage.getItem('md_token')
                let view = this;
                return axios({
                    method: 'put',
                    url: '/api/user',
                    headers: {'Authorization': 'Bearer ' + token},
                    data: payload
                })
            },
            SendDeactivateTwoFactorEmail: function() {
                  let token = localStorage.getItem('md_token')
                  var payload = {
                      command: "requestEmailCode",
                      id: this.id
                  }
                  return axios({
                      method: 'put',
                      url: '/api/user',
                      headers: {'Authorization': 'Bearer ' + token},
                      data: payload
                  });
            },
            deactivateTwoFactor: function (twofactorcode) {

                var payload = {
                    command: "deactivateTwoFactor",
                    id: this.id,
                    password: twofactorcode
                }
                let token = localStorage.getItem('md_token');
                let view = this;
                return axios({
                    method: 'put',
                    url: '/api/user',
                    headers: {'Authorization': 'Bearer ' + token},
                    data: payload
                })
            },
            logout: function(){
                localStorage.removeItem("md_token");
                this.returnToIndex();
                this.$parent.state.locked = false;
                this.$parent.state.toggled = false;
            },
            returnToIndex: function(){
                this.$router.replace('/');
            },
            showCustomModal: function(modal){
                this.$modal.show(modal);
            },
            OpenChangeFirstNamePopup(){
                let view = this;
                this.$helpers.OpenPopup({
                    header: 'Vorname ändern',
                    width: '600px',
                    components: [{
                        type: 'datafill',
                        state: {
                            entries: [{key: 'firstname', type: 'text', value: this.firstname, label:'Neue Vorname'}],
                            selectCallback: (data, datafill) => {
                                datafill.loading = true;
                                view.changeFirstName(data.firstname).then((response) => {
                                    view.firstname = data.firstname;
                                    datafill.Toggle();
                                }).catch((error) => {
                                    datafill.loading = false;
                                    if (error.response != undefined && error.response.data.message != undefined) {
                                        datafill.errors = {};
                                        datafill.errors['firstname'] = {
                                            key: 'firstname',
                                            type: 'error',
                                            text: 'Änderung konnte nicht durchgeführt werden',
                                            tooltip: 'Bitte versuchen Sie es erneut oder kontaktieren Sie einen Administrator'
                                        };
                                    }
                                })
                            },
                        },
                    }],
                    centered: true,

                });
            },
            OpenChangeLastNamePopup(){
                let view = this;
                this.$helpers.OpenPopup({
                    header: 'Nachname ändern',
                    width: '600px',
                    components: [{
                        type: 'datafill',
                        state: {
                            entries: [{key: 'lastname', type: 'text', value: this.lastname, label:'Neue Nachname'}],
                            selectCallback: (data, datafill) => {
                                datafill.loading = true;
                                view.changeLastName(data.lastname).then((response) => {
                                view.lastname = data.lastname;
                                datafill.Toggle();
                              }).catch((error) => {
                                  datafill.loading = false;
                                  if(error.response != undefined && error.response.data.message != undefined){
                                      datafill.errors = {};
                                      datafill.errors['lastname'] = {key: 'lastname', type: 'error', text: 'Änderung konnte nicht durchgeführt werden', tooltip: 'Bitte versuchen Sie es erneut oder kontaktieren Sie einen Administrator'};
                                  }
                              })
                            },
                        },
                    }],
                    centered: true,

                });
            },
            OpenChangePasswordPopup(){
                let view = this;
                this.$helpers.OpenPopup({
                    header: 'Passwort ändern',
                    width: '600px',
                    components: [{
                        type: 'datafill',
                        state: {
                              descriptionSub: 'Password kann nur mit 2F Authentifizierung geändert werden. Bitte folgen Sie der Anweisung im E-mail',
                            entries: [
                                {key: 'password', type: 'password', value: '', label:'Altes Passwort'},
                                {key: 'newpassword', type: 'password', value: '', label:'Neues Passwort'},
                                {key: 'newpasswordconfirm', type: 'password', value: '', label:'Passwort wiederholen'},
                                ],
                            errorCheck: [
                                (entries) => {
                                    if(entries.hasOwnProperty('newpassword') && entries.hasOwnProperty('newpasswordconfirm') && entries['newpassword'] !== entries['newpasswordconfirm']) return {key: 'newpassword', type: 'error', text: 'Passwort stimmt nicht überein'}
                                    else return null;
                                },
                                (entries) => {
                                    if(entries.hasOwnProperty('newpassword') && entries.hasOwnProperty('newpasswordconfirm') && entries['newpassword'] !== entries['newpasswordconfirm']) return {key: 'newpasswordconfirm', type: 'error', text: 'Passwort stimmt nicht überein'}
                                    else return null;
                                },
                            ],
                            selectCallback: (data, datafill) => {
                                datafill.loading = true;
                                view.changePassword(data.password, data.newpassword).then((response) => {
                                    //view.lastname = data.lastname;
                                    datafill.Toggle();
                                }).catch((error) => {
                                    datafill.loading = false;
                                    if(error.response != undefined && error.response.data.message != undefined){
                                        datafill.errors = {};
                                        datafill.errors['password'] = {key: 'password', type: 'error', text: 'Änderung konnte nicht durchgeführt werden', tooltip: 'Bitte versuchen Sie es erneut oder kontaktieren Sie einen Administrator'};
                                        datafill.errors['newpassword'] = {key: 'password', type: 'error', text: 'Änderung konnte nicht durchgeführt werden', tooltip: 'Bitte versuchen Sie es erneut oder kontaktieren Sie einen Administrator'};
                                        datafill.errors['newpasswordconfirm'] = {key: 'password', type: 'error', text: 'Änderung konnte nicht durchgeführt werden', tooltip: 'Bitte versuchen Sie es erneut oder kontaktieren Sie einen Administrator'};
                                    }
                                })
                            },
                        },
                    }],
                    centered: true,
                });
            },
            OpenChangeEmailPopup(){
                let view = this;
                this.$helpers.OpenPopup({
                    header: 'E-mail Adresse ändern',
                    width: '600px',
                    components: [{
                        type: 'datafill',
                        state: {
                            descriptionSub: 'Bitte geben Sie ihre neue E-Mail Adresse ein und bestätigen Sie diese mit der eingabe Ihres Passworts.',
                            entries: [
                                {key: 'email', type: 'text', value: '', label:'Neue E-Mail'},
                                {key: 'password', type: 'password', value: '', label:'Passwort'}],
                            errorCheck: [
                                (entries) => {
                                    if(entries.hasOwnProperty('password') && entries.password === '') return {key: 'password', type: 'error', text: 'Passwort eingeben'}
                                    else return null;
                                },
                                (entries) => {
                                  if(!view.$helpers.ValidEmailCheck(entries['email'])) return {key: 'email', type: 'error', text: 'Falsche E-mail'}
                                  else return null;
                              },
                            ],
                            selectCallback: (data, datafill) => {
                                datafill.loading = true;
                                view.changeEmail(data.email, data.password).then((response) => {
                                    view.email = data.email;
                                    datafill.Toggle();
                                }).catch((error) => {
                                    datafill.loading = false;
                                    if(error.response != undefined && error.response.data.message != undefined){
                                        datafill.errors = {};
                                        datafill.errors['password'] = {key: 'password', type: 'error', text: 'Änderung konnte nicht durchgeführt werden', tooltip: 'Bitte versuchen Sie es erneut oder kontaktieren Sie einen Administrator'};
                                        //view.toggleModal('Fehler', '<p>Änderung konnte nicht durchgeführt werden.</p><p>Bitte versuchen Sie es erneut oder kontaktieren Sie einen Administrator.</p>');
                                    }
                                })
                            },
                        },
                    }],
                    centered: true,
                });
            },
            hideCustomModal: function(modal){
                this.$modal.hide(modal);
            },
            toggleModal(title, text, text2) {
                this.$helpers.OpenInfoPopup(title, [text, text2]);
            },
            Toggle2Factor(){
                if(this.twofactor){
                    if(this.twofactor && this.twofactortype === 'EMAIL'){
                        this.SendDeactivateTwoFactorEmail().then(response => {
                            this.OpenDeactivate2FPopup();
                        }).catch(error => {
                            view.toggleModal('Fehler', '<p>2F Aktivierung konnte nicht durchgeführt werden.</p><p>Bitte versuchen Sie es erneut oder kontaktieren Sie einen Administrator.</p>');
                        })
                    } else {
                        this.OpenDeactivate2FPopup();
                    }
                } else {
                    this.OpenChoose2FPopup();
                }
            },
            OpenChoose2FPopup(){
                let view = this;
                this.$helpers.OpenPopup({
                    header: '2F Option',
                    width: '600px',
                    components: [{
                        type: 'datafill',
                        state: {
                            descriptionSub: 'Bitte wählen Sie aus, mit welcher Variante Sie die Zwei Faktor Authentifizierung durchführen wollen',
                            entries: [
                                {key: 'twofactortype', type: 'bool', value: true, valueFixed: true, label:'Authenticator App:'}],
                            selectCallback: (data, datafill) => {
                                datafill.loading = true;
                                view.requestTwoFactor(data.twofactortype ? 'GAUTH' : 'EMAIL').then(response => {
                                    datafill.Toggle();
                                    view.totpuri = response.data.totpuri;
                                    view.OpenActivate2FPopup();
                                }).catch(function (error) {
                                    datafill.loading = false;
                                    if(error.response != undefined && error.response.data.message != undefined){
                                        view.toggleModal('Fehler', '<p>2F Aktivierung konnte nicht durchgeführt werden.</p><p>Bitte versuchen Sie es erneut oder kontaktieren Sie einen Administrator.</p>');
                                    }
                                })
                            },
                        },
                    }],
                    centered: true,
                });
            },
            OpenActivate2FPopup(){
                let view = this;
                this.$helpers.OpenPopup({
                    header: '2F aktivieren',
                    width: '600px',
                    components: [{
                        type: 'datafill',
                        state: {
                            descriptionSub: 'Bitte speichern Sie den folgenden QR-Code in Ihrer Authenticator App und bestätigen Sie anschließend die Aktivierung mit einem güligten Zwei Faktor Code Falls sie noch keine Authenticator App haben, können Sie den <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2">Google Authenticator</a> verwenden',
                            entries: [
                                {key: 'qrcode', type: 'qrcode', value: view.totpuri, label:'', styles: {height: '300px'}},
                                {key: 'twofactorcode', type: 'passwordsplit', value: '', length: 6, placeholder:'2F Code', label: '', styles: {'margin-top': '-20px !important'}}],
                            positiveButtonStyle: {
                                position: 'absolute',
                                right: '60px',
                                'margin-top': '00px !important'},
                            selectCallback: (data, datafill) => {
                                datafill.loading = true;
                                view.activateTwoFactor(data.twofactorcode).then(response => {
                                    let backupCodes = response.data.backup;
                                    let text = '<p>2F Auth wurde erfolgreich aktiviert!</p><p>Ihre Backup Codes lauten:</p><ul>';
                                    for (const i of backupCodes) {
                                        text = text+'<li style="margin-left: 30px">'+i+'</li>'
                                    }
                                    text = text+'</ul><p>Bitte bewahren Sie diese sorgfältig auf.<p><p>Jeder Backup Code kann nur einmal statt einem Code aus Ihrer Authenticator App benutzt werden.</p>';

                                    view.toggleModal('Erfolg', text);
                                    view.twofactor = true;
                                    view.loadProfile();
                                    datafill.Toggle();
                                }).catch(function (error) {
                                    datafill.loading = false;
                                    if(error.response != undefined && error.response.data.message != undefined){
                                        view.toggleModal('Fehler', '<p>2F Aktivierung konnte nicht durchgeführt werden.</p><p>Bitte versuchen Sie es erneut oder kontaktieren Sie einen Administrator.</p>');
                                    }
                                })
                            },
                        },
                    }],
                    centered: true,
                });
            },
            OpenDeactivate2FPopup(){
                let view = this;

                this.$helpers.OpenPopup({
                    header: '2F deaktivieren',
                    width: '600px',
                    components: [{
                        type: 'datafill',
                        state: {
                            entries: [
                                {key: 'info', type: 'output', value: 'Bitte bestätigen Sie die Deaktivierung der Zwei Faktor Authentifizierung mit einem güligten Zwei Faktor Code:', label:'', dataStyles :{'text-align': 'left'}},
                                {key: 'twofactorcode', type: 'text', value: '', placeholder:'2F Code', label: ''}],

                            selectCallback: (data, datafill) => {
                                datafill.loading = true;
                                view.deactivateTwoFactor(data.twofactorcode).then(response => {
                                    view.toggleModal('Erfolg', '<p>2F Auth wurde erfolgreich deaktiviert</p>');
                                    view.twofactor = false;

                                }).catch(function (error) {
                                    datafill.loading = false;
                                    if(error.response != undefined && error.response.data.message != undefined){
                                        view.toggleModal('Fehler', '<p>2F Deaktivierung konnte nicht durchgeführt werden.</p><p>Bitte versuchen Sie es erneut oder kontaktieren Sie einen Administrator.</p>');
                                    }
                                })
                            },
                        },
                    }],
                    centered: true,
                });
            },
            RouterToOverview(){
                this.$router.push({name: 'Overview'});
            },
        },
        watch: {
            '$route' (to, from) {
                console.log(to);
                this.id = to.params.id;
                this.token = localStorage.getItem('md_token')
                if(this.isBlank(this.token)){
                    this.returnToIndex();
                } else {
                    this.loadProfile();
                }
            },
            avatar: {
                handler: function() {
                    this.savedImage = false
                },
                deep: true
            }
        },
        computed: {
            buttonStyle () {
                return {
                    flex: `1 1 ${100 / 2}%`
                }
            },
            computedProfileImage() {
                if(this.avatar != null){
                    return this.avatar.imageURL;
                }
                else if(this.isBlank(this.profileimage)){
                    return require("../..//assets/serviceimg/logo.png");
                } else {
                    return this.profileimage.url;
                }
            }
        }

    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .profile-panel {
    width: 600px !important;
  }




  .profile-container.card {
    max-width: 100%;
    padding: 40px 40px;
  }
  .profile-user-info {
    display: table;
    width: 98%;
    width: calc(100% - 24px);
    margin: 0 auto
  }

  .profile-info-row {
    display: table-row
  }

  .profile-info-name,
  .profile-info-value {
    display: table-cell;
    border-top: 1px dotted #D5E4F1
  }

  .profile-info-name {
    text-align: right;
    padding: 6px 10px 6px 4px;
    font-weight: 400;
    color: #667E99;
    background-color: transparent;
    width: 110px;
    vertical-align: middle
  }

  .profile-info-value {
    padding: 6px 4px 6px 6px
  }

  .profile-info-value>span+span:before {
    display: inline;
    content: ",";
    margin-left: 1px;
    margin-right: 3px;
    color: #666;
    border-bottom: 1px solid #FFF
  }

  .profile-info-value>span+span.editable-container:before {
    display: none
  }

  .profile-info-row:first-child .profile-info-name,
  .profile-info-row:first-child .profile-info-value {
    border-top: none
  }

  .profile-user-info-striped {
    border: 1px solid #DCEBF7
  }

  .profile-user-info-striped .profile-info-name {
    color: #336199;
    background-color: #EDF3F4;
    border-top: 1px solid #F7FBFF
  }

  .profile-user-info-striped .profile-info-value {
    border-top: 1px dotted #DCEBF7;
    padding-left: 12px
  }

  .link-container{
    text-align: center;
    bottom: 25px;

    left: 0;
    right: 0;
    margin: auto;
    position: absolute;
    white-space: nowrap;
  }

  .link-container img{
    cursor: pointer;
    width: 96px;
    height: 96px;
    margin: 0 12px;
  }

  .link-container img.mp{
    cursor: pointer;
    width: 102px;
    height: 102px;
    margin: 0 10px;
  }

  .footer{
    margin-bottom: 96px;
    padding-top: 25px;
  }
</style>
