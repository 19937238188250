 /* eslint-disable */
<template>
  <div>
    <!-- slot for parent component to activate the file changer -->
    <div @click="launchFilePicker()">
      <slot name="activator"></slot>
    </div>
    <!-- image input: style is set to hidden and assigned a ref so that it can be triggered -->
    <input type="file" accept="image/png, image/jpeg"
       ref="file"
       :name="uploadFieldName"
       @change="onFileChange(
          $event.target.name, $event.target.files)"
       style="display:none">
    <!-- error dialog displays any potential error messages -->
    <v-dialog/>
  </div>
</template>

<script>
  export default {
    name: 'image-input',
    data: ()=> ({
      errorDialog: null,
      errorText: '',
      uploadFieldName: 'file',
      maxSize: 1024
    }),
    props: {
    // Use "value" to enable using v-model
      value: Object,
    },
    methods: {
      launchFilePicker(){
        this.$refs.file.click();
	  },
	  getBase64(file) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = error => reject(error);
		});
	  },
      onFileChange(fieldName, file) {
        const { maxSize } = this
        let imageFile = file[0]
        if (file.length>0) {
          let size = imageFile.size / maxSize / maxSize
          if (!imageFile.type.match('image.*')) {
            this.$modal.show('image-error', {
				title: "Fehler",
				text: "Bitte wählen Sie ein Bild aus!",
				buttons: [
					{
						title: 'OK',
						handler: () => {
						this.$modal.hide('dialog')
						}
					}
				]
			}) 
          } else if (size>1) {
            this.$modal.show('image-error', {
				title: "Fehler",
				text: "Die ausgewählte Datei ist zu groß! Bitte wählen Sie ein Bild unter 1MB",
				buttons: [
					{
						title: 'OK',
						handler: () => {
						this.$modal.hide('dialog')
						}
					}
				]
			}) 
          } else {
            // Append file into FormData and turn file into image URL
            let formData = new FormData()
			//let imageURL = URL.createObjectURL(imageFile);
			formData.append(fieldName, imageFile);
			let view =this;
			this.getBase64(imageFile).then(function(result) {
				let imageURL = result;
  				view.$emit('input', { formData, imageURL })
			}, function(err) {
  				console.log(err); // Error: "It broke"
			})
            // Emit the FormData and image URL to the parent component
            
          }
        }
      }
    }
  }
</script>